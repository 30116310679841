@use "abstracts/variables" as *;

.lang-choice-select.mat-select {
  width: unset;

  .mat-select-value {
    max-width: none;
  }

  .mat-select-trigger .mat-select-arrow {
    margin-left: 8px;
  }
}
