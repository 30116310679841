@use "@angular/material" as mat;
@use "abstracts/variables" as *;

.mat-chip.mat-standard-chip.mat-dark-blue {
  @include mat.typography-level($custom-typography, "body-2");

  background-color: $dark-blue;
  color: $white;
  border-radius: 4px;
  padding: 4px 8px;

  &.pointer {
    cursor: pointer;
  }
}
