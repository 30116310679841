@use "sass:math";
@use "@angular/material" as mat;
@use "abstracts/variables" as *;

body .mat-dialog-container {
  padding: 0;
  border-radius: 16px;
  overflow-x: hidden;

  .mat-dialog-actions {
    padding: 0;
    margin: 16px 0 0;
    min-height: unset;
    justify-content: flex-end;
    width: 100%;

    &.centered-dialog-actions {
      justify-content: center;
    }

    > *:not(:first-child):last-child {
      margin-left: auto;
    }
  }
}

body .mat-dialog-title {
  @include mat.typography-level($custom-typography, "headline");

  margin-bottom: 24px;
}

.default-dialog {
  height: 100% !important;
  width: 100% !important;
  max-width: 100% !important;
  max-height: 100% !important;

  .mat-dialog-container {
    border-radius: unset;

    .mat-dialog-content {
      margin: 0;
      padding: 0;
      max-height: 100%;
    }
  }

  .default-dialog-main-container {
    padding: 24px;

    .youtube-player iframe {
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }

  .mat-fab.default-dialog-close-btn {
    position: sticky;
    top: 24px;
    left: 24px;
    margin: 24px 0 0 24px;
    background-color: #fff;
    color: $primary;
  }
}

.simple-dialog {
  max-width: calc(100% - 48px) !important;
  max-height: calc(100% - 48px) !important;

  &.diploma-success-dialog {
    min-height: 40% !important;
  }

  .mat-dialog-container {
    padding: 24px;

    .mat-dialog-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-height: unset;
    }
  }
}

@media #{$breakpoint-tablet} {
  body .mat-dialog-title {
    margin-bottom: 32px;
  }

  .default-dialog {
    .default-dialog-main-container {
      padding: 24px 64px 32px;
    }
  }

  .simple-dialog {
    max-width: math.percentage(math.div(6, 12)) !important;
    max-height: calc(100% - 64px) !important;

    .mat-dialog-container {
      padding: 32px;
    }
  }
}

@media #{$breakpoint-desktop} {
  .simple-dialog {
    max-width: math.percentage(math.div(4, 12)) !important;
  }

  .default-dialog {
    height: unset !important;
    width: 50% !important;
    max-width: 50% !important;
    max-height: calc(100% - 64px) !important;

    .mat-dialog-container {
      border-radius: 16px;
      height: auto;
    }

    .mat-fab.default-dialog-close-btn {
      top: 32px;
      left: 32px;
      margin: 32px 0 0 32px;
    }
  }
}
