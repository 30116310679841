@use "abstracts/variables" as *;

// Themes utilities
.primary {
  color: $primary !important;
}

.bg-primary {
  background-color: $primary !important;
}

.light-yellow {
  color: $light-yellow !important;
}

.bg-light-yellow {
  background-color: $light-yellow !important;
}

.dark-yellow {
  color: $dark-yellow !important;
}

.bg-dark-yellow {
  background-color: $dark-yellow !important;
}

.warn {
  color: $warn !important;
}

.bg-warn {
  background-color: $warn !important;
}

.dark-grey {
  color: $dark-grey !important;
}

.bg-dark-grey {
  background-color: $dark-grey !important;
}

.white {
  color: $white !important;
}

.bg-white {
  background-color: $white !important;
}

.light-blue-1 {
  color: $light-blue-1 !important;
}

.bg-light-blue-1 {
  background-color: $light-blue-1 !important;
}

.light-blue-2 {
  color: $light-blue-2 !important;
}

.bg-light-blue-2 {
  background-color: $light-blue-2 !important;
}

.medium-blue-1 {
  color: $medium-blue-1 !important;
}

.bg-medium-blue-1 {
  background-color: $medium-blue-1 !important;
}

.medium-blue-2 {
  color: $medium-blue-2 !important;
}

.bg-medium-blue-2 {
  background-color: $medium-blue-2 !important;
}

.dark-blue {
  color: $dark-blue !important;
}

.bg-dark-blue {
  background-color: $dark-blue !important;
}

.light-grey {
  color: $light-grey !important;
}

.bg-light-grey {
  background-color: $light-grey !important;
}

.medium-grey-1 {
  color: $medium-grey-1 !important;
}

.bg-medium-grey-1 {
  background-color: $medium-grey-1 !important;
}

.medium-grey-2 {
  color: $medium-grey-2 !important;
}

.bg-medium-grey-2 {
  background-color: $medium-grey-2 !important;
}

.light-green {
  color: $light-green !important;
}

.bg-light-green {
  background-color: $light-green !important;
}

.dark-green {
  color: $dark-green !important;
}

.bg-dark-green {
  background-color: $dark-green !important;
}

// Word
.break {
  word-break: break-all;
}

.break-normal {
  word-break: normal;
}

.hyphens {
  hyphens: auto;
}

.pre-wrap {
  white-space: pre-wrap;
}

.pointer {
  cursor: pointer;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ellipsis-2-lines {
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;

  /* Ellipsis on the second line on most of modern browsers */

  @supports (-webkit-line-clamp: 2) {
    white-space: initial;
    /* stylelint-disable-next-line value-no-vendor-prefix */
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.ellipsis-3-lines {
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;

  /* Ellipsis on the second line on most of modern browsers */

  @supports (-webkit-line-clamp: 3) {
    white-space: initial;
    /* stylelint-disable-next-line value-no-vendor-prefix */
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}

// centering
.center {
  text-align: center;
  justify-content: center;
}

.right {
  float: right;
}

.left {
  text-align: left;
}

// positioning
.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.container-around {
  display: flex;
  justify-content: space-around;
}

.container-baseline {
  display: flex;
  align-items: baseline;
}

.container-between {
  display: flex;
  justify-content: space-between;
}

.container-center {
  display: flex !important;
  align-items: center;
}

.container-center-col {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container-col {
  display: flex;
  flex-direction: column;
}

.container-start {
  display: flex;
  align-items: flex-start;
}

.block {
  display: block;
}

// spacing
@each $size in 0 4 8 12 16 24 32 48 56 64 auto {
  .p-#{$size} {
    padding: #{$size}px !important;
  }

  .pt-#{$size} {
    padding-top: #{$size}px !important;
  }

  .pb-#{$size} {
    padding-bottom: #{$size}px !important;
  }

  .pl-#{$size} {
    padding-left: #{$size}px !important;
  }

  .pr-#{$size} {
    padding-right: #{$size}px !important;
  }

  .m-#{$size} {
    margin: #{$size}px !important;
  }

  .mt-#{$size} {
    margin-top: #{$size}px !important;
  }

  .mb-#{$size} {
    margin-bottom: #{$size}px !important;
  }

  .ml-#{$size} {
    margin-left: #{$size}px !important;
  }

  .mr-#{$size} {
    margin-right: #{$size}px !important;
  }
}

.plr-16 {
  padding-left: 16px;
  padding-right: 16px;
}

.plr-24 {
  padding-left: 24px;
  padding-right: 24px;
}

.plr-32 {
  padding-left: 32px;
  padding-right: 32px;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

/* - MATERIAL - */
.mat-checkbox-layout {
  white-space: normal !important;
}

// Grid utilities class
@each $size in 1 2 3 4 5 6 7 8 9 10 11 12 {
  .col-#{$size} {
    width: calc((#{$size} / 12) * 100%) !important;
  }
}

.m-auto {
  margin: 0 auto !important;
}

.font-shadow {
  font-family: "Shadows Into Light", sans-serif;
}
