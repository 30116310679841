@use "@angular/material" as mat;
@use "abstracts/variables" as *;

body {
  .mat-accordion {
    .mat-expansion-panel,
    .mat-expansion-panel:last-of-type,
    .mat-expansion-panel:first-of-type {
      border-radius: 8px;

      .mat-expansion-panel-header {
        @include mat.typography-level($custom-typography, "body-1");

        padding-left: 24px;
        padding-right: 24px;
      }

      .mat-expansion-panel-content {
        .mat-expansion-panel-body {
          padding-left: 48px;
          padding-right: 48px;

          @include mat.typography-level($custom-typography, "caption");
        }
      }

      &:not([class*="mat-elevation-z"]) {
        box-shadow: $card-shadow;
      }
    }

    &.small-padding {
      /* stylelint-disable-next-line no-descending-specificity */
      .mat-expansion-panel {
        .mat-expansion-panel-header {
          padding-left: 16px;
          padding-right: 16px;
        }

        .mat-expansion-panel-content {
          .mat-expansion-panel-body {
            padding-left: 32px;
            padding-right: 32px;
          }
        }
      }
    }
  }
}
