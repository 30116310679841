@use "@angular/material" as mat;
@use "abstracts/variables" as *;

.verified-tooltip.mat-tooltip {
  @include mat.typography-level($custom-typography, "caption");

  background: $white;
  color: $dark-grey;
  text-align: center;
  margin: 0 -15px 0 0;
  max-width: 135px;
  padding: 8px;
  box-shadow: $card-shadow;
}

@media #{$breakpoint-desktop} {
  .verified-tooltip.mat-tooltip {
    margin: 0;
  }
}
