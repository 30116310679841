@use "abstracts/variables" as *;

.mat-autocomplete-panel.default-autocomplete {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  .mat-option {
    line-height: 24px;
    height: unset;
    padding: 16px 64px;

    &:not(:last-child) {
      border-bottom: 1px solid $medium-grey-1;
    }
  }
}
