// NOTES : inspired by https://itnext.io/structuring-your-sass-projects-c8d41fa55ed4

@use "@angular/material" as mat;
@use "abstracts/variables" as *;
@use "abstracts/utilities";
@use "abstracts/mixins";
@use "abstracts/system";
@use "base/typography";
@use "components/accordion";
@use "components/autocomplete";
@use "components/button";
@use "components/card";
@use "components/chip";
@use "components/dialog";
@use "components/drawer";
@use "components/form";
@use "components/grid";
@use "components/icons";
@use "components/list";
@use "components/menu";
@use "components/progress-bar";
@use "components/select";
@use "components/slide-toggle";
@use "components/snackbar";
@use "components/spinner";
@use "components/stepper";
@use "components/tab-group";
@use "components/tooltip";
@use "templates/navigation";
@use "themes/gps" as themes;

// Initialise Material custom theme
@include mat.all-legacy-component-themes(themes.$gps-theme);

/* You can add global styles to this file, and also import other style files */

/* global */
html,
body {
  margin: 0;
  height: 100%;
  overflow-y: auto;
}

html {
  background-color: $white;
}

body {
  /* Disables pull-to-refresh but allows overscroll glow effects. */
  overscroll-behavior-y: contain;
}

app-root {
  height: 100%;
}

///////////////////////////////////////////////////
///// TODO ranger le css ci-dessous ailleurs /////
/////////////////////////////////////////////////
body .mat-divider.primary {
  background-color: $dark-blue; // FIXME: find the good color
}

/* -- MAT TAB GROUP -- */
.hide-tabs .mat-tab-header {
  display: none !important;
}

body .mat-tab-group {
  background-color: $white;
  min-height: 50vh;

  .mat-tab-header {
    border-color: $medium-grey-1;
  }
}

/* -- DIVIDER -- */
body .mat-divider {
  border-color: $medium-grey-1;
  margin: 8px 0 16px;
}

body .mat-card .mat-divider-horizontal {
  position: relative;
}

/* -- PROGRESS BAR -- */
body .mat-progress-bar {
  border-radius: 8px;
  height: 8px;

  & .mat-progress-bar-fill::after {
    border-radius: 8px;
  }
}

[hidden] {
  display: none !important;
}

.mat-toolbar {
  background-color: $white !important;
  border-bottom: 1px solid $medium-grey-1;
}

body .mat-drawer-container {
  background-color: $white;
  z-index: 2;
}

/* -- NGX MAT FILE INPUT -- */
.ngx-mat-file-input {
  button {
    line-height: normal;
  }

  .mat-form-field-suffix {
    top: auto !important;
  }
}

/* -- STEP INSTRUCTIONS -- */
.instruction-number {
  border: 1px solid $medium-grey-1;
  border-radius: 50%;
  margin-bottom: 8px;
  color: $medium-grey-1;
  width: 32px;
  height: 32px;
  text-align: center;
  padding: 4px 0;
  box-sizing: border-box;
}

/* -- HOTJAR FEEDBACK -- */
/* stylelint-disable selector-class-pattern */
._hj_feedback_container
  ._hj-1O2Rr__MinimizedWidgetMiddle__container._hj-1QnQT__MinimizedWidgetMiddle__right {
  top: 109px !important;
}
/* stylelint-enable selector-class-pattern */
