@use "abstracts/variables" as *;

body {
  .mat-button,
  .mat-flat-button,
  .mat-raised-button,
  .mat-stroked-button {
    line-height: 48px;
    border-radius: 8px;
    color: $primary;

    // &.secondary {
    //   background-color: white;
    //   border: 1px solid $primary;
    //   width: fit-content;
    // }

    &.two-lines-button {
      min-height: 48px;
      line-height: 36px;
      white-space: normal;
    }

    .mat-button-wrapper {
      line-height: 24px;

      .mat-icon {
        margin-right: 8px;
        fill: $primary !important;
      }
    }

    &.mat-secondary {
      border: 1px solid $primary;
      background-color: transparent; /* bad idea ... */
    }

  }



  .mat-stroked-button {
    &.mat-primary {
      border-color: $primary;
    }
  }

  .mat-flat-button,
  .mat-raised-button {
    background-color: $light-blue-2;
  }

  .mat-flat-button,
  .mat-raised-button,
  .mat-fab {
    &.mat-primary .mat-button-wrapper .mat-icon {
      fill: $white !important;
    }

    &.mat-white {
      background-color: $white;
    }

    &.mat-dark {
      background-color: $medium-blue-2;
    }
  }

  .mat-fab {
    height: 48px;
    width: 48px;
    z-index: 3;

    &.mat-primary {
      background-color: $white;
      color: $primary;
    }

    .mat-button-wrapper {
      padding: 12px 0;
    }
  }

  .mat-button,
  .mat-flat-button,
  .mat-raised-button,
  .mat-fab {
    &,
    &.mat-primary,
    &.mat-accent,
    &.mat-warn {
      &.mat-button-disabled,
      &:disabled {
        background-color: $light-grey;
        color: $medium-grey-2;
        cursor: default;

        /* stylelint-disable-next-line no-descending-specificity */
        .mat-button-wrapper .mat-icon {
          fill: $white !important;
        }
      }
    }

    &:not(.mat-button-disabled),
    &:not(:disabled) {
      &:hover .mat-button-focus-overlay {
        opacity: 0.04;
      }
    }
  }

  .mat-raised-button:not([class*="mat-elevation-z"]) {
    box-shadow: $poi-shadow;
  }

  .mat-fab:not([class*="mat-elevation-z"]) {
    box-shadow: $poi-shadow;
  }

  .mat-button-base.multi-line-button .mat-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 0;
    min-height: 36px;
    line-height: 18px;

    /* stylelint-disable-next-line no-descending-specificity */
    .mat-icon {
      min-width: 24px;
      min-height: 24px;
    }

    .break-label {
      white-space: pre-wrap;
      word-break: normal;
    }
  }

  .mat-button.nav-menu-btn {
    width: 100%;
    text-align: left;
    color: $medium-grey-2;
    font-weight: 400;
    padding: 0 16px;
    text-decoration: none;
    line-height: 48px;

    /* stylelint-disable-next-line no-descending-specificity */
    .mat-button-wrapper .mat-icon {
      margin-right: 8px;
      fill: $medium-grey-2 !important;

      > * {
        fill: $medium-grey-2 !important;
      }

      &.stroke {
        stroke: $medium-grey-2 !important;
      }
    }

    &.active {
      background-color: $medium-blue-2;
      color: $primary;
      font-weight: 600;

      .mat-button-wrapper .mat-icon {
        fill: $primary !important;

        > * {
          fill: $primary !important;
        }

        &.stroke {
          stroke: $primary !important;
        }
      }
    }
  }
}

.mat-icon-button.mat-medium-grey-2 {
  color: $medium-grey-2;
}

@media #{$breakpoint-desktop} {
  body *:not(.force-vertical-display) > .nav-menu-btn.mat-button {
    text-align: center;
    width: initial;

    &.active {
      background-color: transparent;
    }
  }
}
