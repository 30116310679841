@use "@angular/material" as mat;
@use "abstracts/variables" as *;

/* stylelint-disable no-invalid-position-at-import-rule */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Shadows+Into+Light&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Shadows+Into+Light+Two&display=swap");

/* stylelint-enable no-invalid-position-at-import-rule */
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($custom-typography);`
@include mat.all-legacy-component-typographies($custom-typography);
@include mat.legacy-core(); // Be sure that you only ever include this mixin once!

$map-factor: 2;

.mat-typography h1,
.mat-headline,
.mat-typography h2,
.mat-title {
  color: $dark-grey;
  margin-bottom: 24px;
}

body {
  .mat-toolbar,
  .mat-toolbar h1,
  .mat-toolbar h2,
  .mat-toolbar h3,
  .mat-toolbar h4,
  .mat-toolbar h5,
  .mat-toolbar h6 {
    @include mat.typography-level($custom-typography, "body-2");
  }

  a {
    @include mat.typography-level($custom-typography, "body-2");

    color: $primary;

    &.link-without-decoration {
      text-decoration: none;
    }

    &[type="button"] {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .global-map-mat-body-stronger {
    @include mat.typography-level($custom-typography, "body-2");

    font-size: mat.font-size($custom-typography, "body-2") * $map-factor;
    line-height: 45px;
    font-weight: 600;
  }

  .global-map-mat-body-strong {
    @include mat.typography-level($custom-typography, "body-2");

    font-size: mat.font-size($custom-typography, "body-2") * $map-factor;
    line-height: 45px;
    font-weight: 400;
  }

  .global-map-mat-caption {
    @include mat.typography-level($custom-typography, "caption");

    font-size: mat.font-size($custom-typography, "caption") * $map-factor;
    line-height: mat.line-height($custom-typography, "caption") * $map-factor;
  }
}

@media #{$breakpoint-tablet} {
  .mat-typography h1,
  .mat-headline {
    margin-bottom: 32px;
  }
}
