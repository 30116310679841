@use "@angular/material" as mat;
@use "abstracts/variables" as *;
@use "sass:map";

body {
  .mat-card {
    border-radius: 8px;
    padding: 24px;
    min-width: 340px;
    box-shadow: none !important;
    background-color: $light-grey;

    &.small-padding-card {
      padding: 16px;
    }

    &.full-width-card {
      width: 100%;
      box-sizing: border-box;
    }

    &.full-height-card {
      display: flex;
      flex-direction: column;
      height: 100%;
      box-sizing: border-box;
    }

    &.clickable-card {
      cursor: pointer;
      background-color: white;

      &::after {
        background-color: #000;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: absolute;
        border-radius: inherit;
        opacity: 0;
        content: "";
        pointer-events: none;
        transition: opacity 200ms cubic-bezier(0.35, 0, 0.25, 1);
      }

      &:hover::after {
        opacity: 0.04;
      }



      &:not([class*="mat-elevation-z"]) {
        box-shadow: $card-shadow !important;
      }

      &:not([class*="mat-elevation-z"]).card-without-shadow {
        box-shadow: none;
      }

    }

    &:not(.small-padding-card) .mat-card-image {
      margin-right: -24px;
      margin-left: -24px;
      width: calc(100% + 48px);

      &:first-child {
        margin-top: -24px;
      }
    }

    .mat-card-actions,
    .mat-card-actions:last-child {
      padding: 16px 0 0;
      margin: auto 0 0;
      display: flex;

      &:not(.no-wrap-card-actions) {
        flex-wrap: wrap;
      }

      &.centered-card-actions {
        justify-content: center;
      }

      > *:not(:last-child) {
        margin-right: 16px;
      }
    }

    /* stylelint-disable-next-line no-descending-specificity */
    .mat-card-title + .mat-card-actions {
      padding-top: 0;
    }

    .mat-card-title.mat-body-strong + .mat-card-actions {
      padding-top: 8px;
    }
  }

  .responsive-card {
    .mat-card-title.mat-body-strong {
      font-size: map.get($mobile-body-2-bolder, font-size);
      font-weight: map.get($mobile-body-2-bolder, font-weight);
      line-height: map.get($mobile-body-2-bolder, line-height);
    }

    .mat-card-actions .mat-button-base {
      font-size: map.get($mobile-body-2, font-size);
      font-weight: map.get($mobile-body-2, font-weight);
    }

    .mat-card-content.mat-caption {
      font-size: map.get($mobile-caption, font-size);
      font-weight: map.get($mobile-caption, font-weight);
      line-height: map.get($mobile-caption, line-height);
    }
  }

  .mat-card-title {
    @include mat.typography-level($custom-typography, "title");

    margin-bottom: 16px;

    &.mat-body-strong {
      @include mat.typography-level($custom-typography, "body-2");

      margin-bottom: 8px;
    }
  }
}

.mat-card-content {
  margin: 0;
}

@media #{$breakpoint-desktop} {
  body .responsive-card {
    .mat-card-title.mat-body-strong {
      font-size: map.get($not-mobile-body-2-bolder, font-size);
      font-weight: map.get($not-mobile-body-2-bolder, font-weight);
      line-height: map.get($not-mobile-body-2-bolder, line-height);
    }

    .mat-card-content.mat-caption {
      @include mat.typography-level($custom-typography, "caption");
    }

    .mat-card-actions .mat-button-base {
      font-size: map.get(map.get($custom-typography, "body-2"), font-size);
    }
  }
}
