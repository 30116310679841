@use "abstracts/variables" as *;

body {
  .mat-step-icon {
    height: 32px;
    width: 32px;
  }

  .mat-vertical-content-container {
    margin-left: 40px;
  }
}
