@use "abstracts/variables" as *;

.mat-icon {
  flex: 0 0 auto;
  vertical-align: sub;
}

.mat-icon.icon-xl {
  height: 64px !important;
  width: 64px !important;
}

h1,
.mat-headline {
  .mat-icon {
    height: 48px;
    width: 48px;
  }

  &.mat-icon {
    height: 48px !important;
    width: 48px !important;
  }
}

h2,
.mat-title {
  /* stylelint-disable-next-line no-descending-specificity */
  .mat-icon {
    height: 32px;
    width: 32px;
  }

  &.mat-icon {
    height: 32px !important;
    width: 32px !important;
  }
}

.mat-caption {
  .mat-icon {
    height: 16px;
    width: 16px;
  }

  &.mat-icon {
    height: 16px !important;
    width: 16px !important;
  }
}

.mat-icon.mat-medium-grey-2 {
  > * {
    fill: $medium-grey-2 !important;
  }
}

.mat-icon[data-mat-icon-name="line"] {
  margin-left: 14px;
  margin-top: 4px;
}

// -- ELLIPSE --
.ellipse {
  background-color: #fff;
  width: 14px;
  height: 14px;
  border: 2px solid $primary;
  border-radius: 50%;
  flex-shrink: 0;
}
