@use "@angular/material" as mat;
@use "sass:map";

// TODO Review the breakpoints in the app using these new breakpoints
$sm: 640px;
$md: 768px;
$lg: 1024px;
$xl: 1280px;
$xxl: 1536px;

$breakpoint-sm: "screen and (min-width: 640px)";
$breakpoint-md: "screen and (min-width: 768px)";
$breakpoint-lg: "screen and (min-width: 1024px)";
$breakpoint-xl: "screen and (min-width: 1280px)";
$breakpoint-xxl: "screen and (min-width: 1536px)";

$breakpoint-grid-sm: "screen and (min-width: 640px)";
$breakpoint-grid-md: "screen and (min-width: 970px)";
$breakpoint-grid-xl: "screen and (min-width: 1430px)";
$breakpoint-grid-xxl: "screen and (min-width: 1910px)";

// TODO: Remove below
// ___________ start ___________
$breakpoint-tablet: "(min-width: 600px) and (orientation: portrait), (min-width: 960px) and (orientation: landscape)";
$breakpoint-desktop: "(min-width: 840px) and (orientation: portrait), (min-width: 960px) and (orientation: landscape)";
$material-toolbar-breakpoint: "(min-width: 600px)";
$small-phone: 320px;
$tablet: 744px;
$desktop: 1024px;
// ___________ end ___________

$toolbar-height: 64px;
$toolbar-height-s: 56px;
$bottomnav-height: 56px;

// Colors

// Primary
$primary: #0a99b7;
$dark-grey: #333;
$white: #fff;

// Secondary
$light-blue-1: #ebf7f9;
$light-blue-2: #d8eff3;
$medium-blue-1: #c4e7ee;
$medium-blue-2: #b1dee8;
$dark-blue: #373365;

// Structural
$light-grey: #f5f5f5;
$medium-grey-1: #d6d6d6;
$medium-grey-2: #808080;

// Tertiary
$light-yellow: #f4ae1a;
$dark-yellow: #d59818;
$light-background-yellow: #fdf2da;
$warn: #f0624d;
$light-background-orange: #fde6e3;
$light-green: #c2d535;
$dark-green: #a0b02c;
$light-background-green: #f5f8df;

$custom-typography: mat.define-legacy-typography-config(
  $font-family: "Poppins, sans-serif",
  $headline: mat.define-typography-level(36px, 48px, 800),
  $title: mat.define-typography-level(24px, 32px, 800),
  $body-1: mat.define-typography-level(18px, 24px, 400),
  $body-2: mat.define-typography-level(18px, 24px, 600),
  $caption: mat.define-typography-level(14px, 16px, 400),
  $button: mat.define-typography-level(18px, 48px, 600) /* 48px is 24 in Figma */
);

$mobile-headline: mat.define-typography-level(30px, 40px, 700);
$mobile-title: mat.define-typography-level(20px, 28px, 700);
$mobile-body-1: mat.define-typography-level(16px, 24px, 400);
$mobile-body-2: mat.define-typography-level(16px, 24px, 600);
$mobile-body-2-bolder: mat.define-typography-level(16px, 24px, 800);
$not-mobile-body-2-bolder: mat.define-typography-level(18px, 24px, 800);
$mobile-caption: mat.define-typography-level(12px, 16px, 400);
$mobile-button: mat.define-typography-level(16px, 24px, 600);

// Shadows
$card-shadow: 0 4px 16px rgb(128 128 128 / 20%);
$poi-shadow: 0 2px 4px rgb(128 128 128 / 40%);
$burger-shadow: 4px 0 16px rgb(128 128 128 / 56%);
$drop-shadow: 0 1px 0 #d6d6d6;
