@use "@angular/material" as mat;
@use "abstracts/variables" as *;

// Credits: https://stackoverflow.com/a/49815388/7916042
// Workaround for multi-line field error (https://github.com/angular/material2/issues/4580)
mat-form-field .mat-form-field {
  &-underline {
    position: relative;
    bottom: auto;
  }

  &-subscript-wrapper {
    position: static;
  }
}

body .mat-form-field-appearance-outline {
  & .mat-form-field-infix {
    padding: 12px 0;
    border: 0;
  }

  & .mat-form-field-flex {
    padding: 0 16px;
    margin: 0;
  }
}

.default-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .mat-form-field-appearance-outline:not(.mat-form-field-appearance-legacy) {
    .mat-form-field-prefix,
    .mat-form-field-suffix {
      top: 0;

      .mat-icon {
        display: inline-block;
      }
    }

    .mat-form-field-prefix .mat-icon {
      margin-right: 24px;
    }

    .mat-form-field-suffix .mat-icon {
      margin-left: 24px;
    }
  }

  .mat-form-field-appearance-outline:not(.mat-form-field-invalid) {
    .mat-form-field-outline-start,
    .mat-form-field-outline-gap,
    .mat-form-field-outline-end {
      border-color: $medium-grey-1;
    }
  }

  .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float
    .mat-form-field-outline-gap {
    border-color: $medium-grey-1;
  }

  .mat-form-field-appearance-outline:is(.mat-form-field-invalid).mat-form-field-can-float.mat-form-field-should-float
    .mat-form-field-outline-gap {
    border-color: $warn;
  }

  > *:not(:last-child):not(.mat-form-field) {
    margin-bottom: 24px;
  }

  > *:not(.mat-button):not(.mat-flat-button):not(.mat-raised-button) {
    width: 100%;
  }

  .mat-checkbox {
    @include mat.typography-level($custom-typography, "caption");
  }

  .mat-form-field-label {
    color: rgb(117 117 117); // The browser default placeholder color
  }

  // Hide label on focus
  .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float
    .mat-form-field-label,
  .mat-form-field-appearance-outline.mat-form-field-can-float
    .mat-input-server:focus
    + .mat-form-field-label-wrapper
    .mat-form-field-label {
    display: none;
  }

  .mat-form-field-appearance-outline .mat-select-arrow-wrapper {
    transform: none;
  }

  .mat-form-field-appearance-outline .mat-form-field-wrapper {
    margin: 0;
  }

  .mat-form-field {
    line-height: mat.line-height($custom-typography, "body-1");
  }
}

.mat-form-field-appearance-outline .mat-form-field-wrapper .mat-form-field-outline {
  background-color: $white;
  border-radius: 5px;
  top: 0;
}

body .mat-input-element,
body .mat-select {
  background-color: $white;
}
