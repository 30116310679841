@use "abstracts/variables" as *;

.mat-ink-bar {
  height: 4px !important;
}

.mat-tab-labels {
  .mat-tab-label {
    font-weight: normal;
  }

  .mat-tab-label-active {
    color: $primary;
    font-weight: 600;
  }
}

.mat-tab-links {
  .mat-tab-link {
    font-weight: normal;
  }

  .mat-tab-label-active {
    color: $primary;
    font-weight: 600;
  }
}

.mat-tab-group {
  .mat-tab-body {
    max-width: 100%;

    &.mat-tab-body-active {
      overflow: visible;
    }
  }

  .mat-tab-body-wrapper {
    overflow: visible;
  }

  .mat-tab-body-content {
    padding: 24px;
    box-sizing: border-box;
    overflow: visible;

    > *:not(:last-child) {
      margin-bottom: 24px;
    }
  }
}

.mat-tab-body.mat-tab-body-active {
  animation: fade-in 400ms;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@media #{$breakpoint-desktop} {
  .mat-tab-group:not(.tab-group-always-padding) .mat-tab-body-content {
    padding: 24px 0;
  }
}
