.mat-drawer.main-drawer .mat-drawer-inner-container {
  display: flex;
  flex-direction: column;
}

.map-drawer.menu-drawer {
  .village-details,
  .choose-container {
    display: none;
  }
}

.map-drawer.village-details-drawer app-mobile-menu {
  display: none;
}
