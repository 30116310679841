@use "sass:math";
@use "abstracts/variables" as *;

/**
 NOTE : These classes must only be used to a page main container as the 12-column grid is only
 defined page wide.
 Using it within another block would result to columns not aligned on the global grid.

 NOTE : When using these class on a main container, make sure that the wrapper of the main container
 does not have any margin/padding in tablet or desktop so the columns are based on the whole page
 width.
 */

@media #{$breakpoint-tablet} {
  .main-container-12-columns {
    width: 100% !important;
  }

  .main-container-10-columns {
    width: math.percentage(math.div(10, 12)) !important;
    margin-right: auto;
    margin-left: auto;
  }

  .main-container-8-columns {
    width: math.percentage(math.div(8, 12)) !important;
    margin-right: auto;
    margin-left: auto;
  }

  .main-container-6-columns {
    width: math.percentage(math.div(6, 12)) !important;
    margin-right: auto;
    margin-left: auto;
  }
}
