@use "abstracts/variables" as *;

body .mat-progress-bar .mat-progress-bar-buffer,
body .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: $light-grey;
}

body .mat-progress-bar.mat-light-yellow .mat-progress-bar-fill::after {
  background-color: $light-yellow;
}
