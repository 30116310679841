@use "abstracts/variables" as *;

body {
  .mat-list-base {
    padding-top: 0;

    .mat-list-item {
      height: auto;
      padding: 16px 0;

      .mat-list-item-content {
        padding: 0;
      }
    }

    &:not(.no-divider) {
      .mat-list-item:not(:last-child) {
        border-bottom: 1px solid $medium-grey-1;
      }
    }

    .mat-subheader {
      height: auto;
      padding: 0;
      margin-bottom: 8px;
      color: $dark-grey;

      &:first-child {
        margin-top: 0;
      }
    }

    .mat-list-item .mat-list-icon.bac-specialty-icon {
      font-size: 18px;
      margin-right: 4px;
    }
  }
}
