@use "abstracts/variables" as *;

.mat-snack-bar-container.error {
  background-color: $warn;
  white-space: pre-wrap;
}

.mat-snack-bar-container.success {
  background-color: green;
  white-space: pre-wrap;
}

.mat-snack-bar-container.panel {
  background-color: $dark-blue;
}
