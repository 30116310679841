@use "abstracts/variables" as *;
@use "@angular/material" as mat;
@use "sass:map";

/*
  Classes to make theming easily according to the design system of Figma
  https://www.figma.com/design/2FIrZvZW1UCve7NrSMi6ky/Design-system?node-id=3-46&m=dev
  https://docs.google.com/spreadsheets/d/1I24uNzMjA5npwHwUs-SViz67LN4nshkkLC6FfCt9F_Q/edit?gid=0#gid=0
*/

.mr-heading {
  font-size: map.get($mobile-headline, font-size);
  font-weight: map.get($mobile-headline, font-weight);
  line-height: map.get($mobile-headline, line-height);
}

.mr-section-title {
  font-size: map.get($mobile-title, font-size);
  font-weight: map.get($mobile-title, font-weight);
  line-height: map.get($mobile-title, line-height);
}

.mr-card-title {
  font-size: map.get($mobile-body-2, font-size);
  font-weight: map.get($mobile-body-2, font-weight);
  line-height: map.get($mobile-body-2, line-height);
}

.mr-button {
  font-size: map.get($mobile-button, font-size);
  font-weight: map.get($mobile-button, font-weight);
  line-height: map.get($mobile-button, line-height);
}

.mr-link {
  font-size: map.get($mobile-button, font-size);
  font-weight: map.get($mobile-button, font-weight);
  line-height: map.get($mobile-button, line-height);
}

.mr-body {
  font-size: map.get($mobile-body-1, font-size);
  font-weight: map.get($mobile-body-1, font-weight);
  line-height: map.get($mobile-body-1, line-height);
}

.mr-caption {
  font-size: map.get($mobile-caption, font-size);
  font-weight: map.get($mobile-caption, font-weight);
  line-height: map.get($mobile-caption, line-height);
}

@media #{$breakpoint-desktop} {
  .mr-heading {
    @include mat.typography-level($custom-typography, "headline");
  }

  .mr-section-title {
    @include mat.typography-level($custom-typography, "title");
  }

  .mr-card-title {
    @include mat.typography-level($custom-typography, "body-2");
  }

  .mr-button {
    @include mat.typography-level($custom-typography, "button");
  }

  .mr-link {
    @include mat.typography-level($custom-typography, "button");
  }

  .mr-body {
    @include mat.typography-level($custom-typography, "body-1");
  }

  .mr-caption {
    @include mat.typography-level($custom-typography, "caption");
  }
}
