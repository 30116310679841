@use "abstracts/variables" as *;

body .mat-menu-trigger.mat-button {
  color: $medium-grey-2;
  font-weight: normal;

  & .mat-icon {
    margin-right: 8px;

    & > * {
      fill: $medium-grey-2 !important;
    }
  }
}

body .mat-menu-panel {
  box-shadow: $card-shadow;
  border-radius: 8px;

  & .mat-menu-item {
    padding: 0 24px;
  }

  & .mat-menu-item .mat-icon {
    margin-right: 8px;

    & > * {
      fill: $medium-grey-2 !important;
    }
  }
}

body .mat-menu-trigger.mat-button[aria-expanded="true"] {
  color: $primary;
  font-weight: 600;

  & .mat-icon > * {
    fill: $primary !important;
  }
}

.mat-menu-panel.profile-menu {
  min-width: 375px;
  margin-top: 8px;
}

.mat-menu-panel.desktop-help-menu {
  margin-bottom: 12px;
}
